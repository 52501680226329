
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Captcha from "@/components/Input/Captcha.vue";
import { DeviceCheck } from "@/mixins/device-check";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: { Captcha },
})
export default class SignIn extends mixins(
  Navigation,
  FormValidations,
  DeviceCheck,
  StyleCheck
) {
  $refs!: {
    signInForm: HTMLFormElement;
  };
  valid = false;
  loading = false;
  showPassword = false;
  hcaptcha_done = false;

  user = {
    identification: "",
    password: "",
  };

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  @Watch("language")
  resetValidations() {
    this.resetFormValidations([this.$refs.signInForm]);
  }

  private async submit() {
    if (this.$refs.signInForm.validate() && this.hcaptcha_done) {
      this.loading = true;
      await this.$store
        .dispatch("authentication/signIn", this.user)
        .then(async () => {
          const isMobile = await this.assertMobileContext(true);
          if (isMobile) {
            return;
          }
          let context = await this.$store.getters["authentication/getContext"];
          if (context == this.$constants.CONTEXT.FRONTOFFICE) {
            this.navigate("/home");
          } else if (context == this.$constants.CONTEXT.BACKOFFICE) {
            this.navigate("/staff/home");
          }
        })
        .catch((error) => {
          let errorMessage: string = this.$tc("SignIn.error");
          if (error.response.status == 401) {
            errorMessage = this.$tc("SignIn.errorCredentials");
            if (error.response.data.blocked) {
              errorMessage = this.$tc("SignIn.errorBlocked");
            }
            if (error.response.data.deleted) {
              errorMessage = this.$tc("SignIn.errorDeleted");
            }
          }
          const Error: Notification = {
            message: errorMessage,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;

      if (this.hcaptcha_done == false) {
        const Error: Notification = {
          message: this.$tc("Views.hCaptchaError1"),
          timeout: "60000",
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      }
    }
  }
}
